/* textColors */
$textColor: var(--black);
$invertedTextColor: var(--white);
$greyTextColor: var(--darkGrey);
$primaryButtonTextColor: var(--textForPrimaryColor);
$placeholderColor: var(--grey);
$transparentButtonTextColor: var(--black2);

/* colors */
$blue: var(--blue);
$white: var(--white);
$primaryColor: var(--primaryColor);
$lightPrimary: var(--lightPrimary);
$black: var(--black);
$black2: var(--black2);
$grey: var(--grey);
$blue: var(--blue);
$grey2: var(--grey2);
$yellow: var(--yellow);
$yellow2: var(--yellow2);
$lightGrey: var(--lightGrey);
$lightGrey2: var(--lightGrey2);
$red: var(--red);

/* general */
$backgroundColor: var(--white);
$imageFallbackBackgroundColor: var(--grey3);
$greyBackgroundColor: var(--grey2);

/* borderColor */
$borderColor: var(--black2);
$borderColor2: var(--grey);
$borderColor3: var(--grey2);
$borderColor4: var(--grey3);

/* borderRadius */
$borderRadiusXs: 0.125rem;
$borderRadiusSm: 0.1875rem;
$borderRadiusMd: 0.25rem;

/* fontFamilies */
$fontFamilyNormal: Lato;
$fontFamilyBold: LatoBold;
$fontFamilyThin: LatoThin;
$fontFamilyLight: LatoLight;
$fontFamilyRegular: LatoRegular;

/* breakpoints */
$media-computer-min: 55em;

/* borderSize */
$borderSizeXs: 0.125rem;
$borderSize: 0.25rem;

/* fontSizes */
$fontSizeH1: 4.5rem;
$fontSizeH2: 3.25rem;
$fontSizeH3: 2.75rem;
$fontSizeH4: 2.25rem;
$fontSizeBig: 3.25rem;
$fontSizeBody: 2rem;
$fontSizeSmall: 1.75rem;
$fontSizeTiny: 1.5rem;

$alertColor: var(--red);
$successColor: var(--green);

/* box shadows */
$buttonBoxShadow: 0 2px 4px transparent;
$buttonBoxShadowHover: 0 2px 4px rgba(0, 0, 0, 0.25);
$boxShadowHover: 0px 2px 4px rgba(0, 0, 0, 0.25);
$boxShadowSelected: 0px 8px 16px rgba(0, 0, 0, 0.23);

/* separator */
$separatorLight: var(--lightGrey2);
$separatorDark: var(--grey3);

/* spacing */
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 16px;
$spacing-xl: 32px;
$spacing-xxl: 40px;

/* size */
$size-xs: 4;
$size-sm: 8;
$size-md: 16;
$size-xl: 32;
$size-xxl: 40;
$size-xxxl: 48;

/* topbar */
$topbarSize: 7rem;

/* sidebar */
$sidebarSize: 31.25rem;
$rightSidebarSize: 44.625rem;
$sidebarBackground: var(--lightGrey);
$sidebarActiveItemTextColor: var(--textForPrimaryColor);
$sidebarItemColor: var(--grey2);
$sidebarActiveItemColor: var(--primaryColor);

/* tags */
$industryTagColor: var(--green);
$standardTagColor: var(--blue);

/* dimmers */
$dimmerColor: var(--black2_opacity05);

@function calculateRem($size) {
  @return ($size / 8) * 1rem;
}

$dropdownIndex: 100000000;
$tooltipIndex: 100000000;
