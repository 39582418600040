@import 'src/shared/globalStyles';

@media only screen and (min-width: $media-computer-min) {
  .loginForm {
    width: 428px;
  }
}

@media only screen and (max-width: $media-computer-min) {
  .loginForm {
    width: calc(100vw - 16px);
  }
}

.loginSeparator {
  width: calculateRem(80);
  max-width: calculateRem(80);
  align-items: center;
}

.loginSeparatorText {
  padding-left: calculateRem(8);
  padding-right: calculateRem(8);
}

.auth {
  &__imageContainer {
    background-color: white;
    margin: calculateRem(64) calculateRem(32) 0 calculateRem(32);
    width: calculateRem(272);
    height: calculateRem(96);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__projectContainer {
    background-color: white;
    margin: calculateRem(8) calculateRem(32) 0 calculateRem(32);
    width: calculateRem(272);
    height: calculateRem(64);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__titleContainer {
    position: absolute;
    margin-top: calculateRem(-48);
    top: 50%;
    height: calculateRem(96);
    margin-left: calculateRem(32);
  }
}
