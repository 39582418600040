@import 'src/shared/globalStyles';

.projectList {
  &__container {
    border: 1px solid $lightGrey2;
    padding: calculateRem(12) calculateRem(24) calculateRem(24);
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $lightGrey2;
    padding: calculateRem(24) 0;

    &:first-child {
      padding-top: calculateRem(16);
    }
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }

  &__highlightedproject {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $lightGrey2;
    padding-right: 20px;
    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }

  &__emptyImage {
    height: calculateRem(243);
    width: calculateRem(255);
  }

  &__itemImage {
    height: calculateRem(24);
    border-radius: calculateRem(3);
    width: calculateRem(24);
  }
}
.red {
  background-color: $red;
}
.yellow {
  background-color: $yellow;
}
.green {
  background-color: $primaryColor;
}
