/* colors */

@font-face {
  font-family: Lato;
  src: url('shared/assets/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: LatoBold;
  src: url('shared/assets/fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: LatoThin;
  src: url('shared/assets/fonts/Lato-Thin.ttf') format('truetype');
}

@font-face {
  font-family: LatoLight;
  src: url('shared/assets/fonts/Lato-Light.ttf') format('truetype');
}

html  , #root, body, .App {
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.disabledClicks > * {
  pointer-events: none;
}
