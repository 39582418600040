@import '../globalStyles.scss';

.input {
  height: 5.375rem;
  background: $backgroundColor;
  border: $borderRadiusSm solid $borderColor;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  font-family: $fontFamilyNormal;
  font-size: $fontSizeBody;
  color: $textColor;
  padding-left: 1.25rem;
  outline: 0;

  &.error {
    border-color: $alertColor;
  }

  &:focus {
    border: $borderRadiusSm solid $primaryColor;
  }

  &:-ms-input-placeholder {
    color: $placeholderColor;
  }

  &::placeholder {
    color: $placeholderColor;
  }
}

.textareaContainer {
  width: 100%;
}

.textarea {
  font-family: $fontFamilyNormal;
  font-weight: 100;
  padding-left: $spacing-sm;
  padding-top: $spacing-sm;
  font-size: $fontSizeBody;
  resize: none;
  width: 100%;
  outline: none;
  border: calculateRem(1.5) solid $borderColor;
  color: $textColor;

  &:focus {
    border-color: $primaryColor;
  }

  &:-ms-input-placeholder {
    color: $placeholderColor;
  }

  &::placeholder {
    color: $placeholderColor;
  }

  &.error {
    border-color: $alertColor;
  }
}

.searchIcon {
  padding: calculateRem(2.5);
  margin-top: calculateRem(9);
  width: calculateRem(42);
}
.searchRow {
  width: calculateRem(250);
}
.searchRow:focus-within {
  border: 0.1875rem solid $primaryColor;
}

.searchInput {
  width: auto;
  height: 99%;
  border: none;
  font-family: $fontFamilyNormal;
  font-size: $fontSizeBody;
  color: $textColor;
  outline: 0;
}
