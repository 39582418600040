@import '../globalStyles.scss';

.sidebarLayout {
  &__title {
    margin: 0 calculateRem(32);
  }
  &__topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    border-bottom: calculateRem(1) solid $borderColor3;
    padding: 0 calculateRem(24);
    position: fixed;
    height: calculateRem(56);
    overflow: hidden;
    z-index: 10;
    background-color: $sidebarBackground;
  }

  &__hiddenButton {
    visibility: hidden;
  }

  &__sidebar {
    height: 100%;
    position: fixed;
    left: 0;
    width: calculateRem(250);
    margin-top: calculateRem(56);
    z-index: 99;
    transform: translateX(-250px);
    transition: transform 250ms ease-in-out;
  }

  &__sidebarOpen {
    transform: none;
  }

  &__content {
    opacity: 0;
    position: fixed;
    background-color: black;
    height: 0;
    width: 0;
    z-index: 9;
    transition: opacity 250ms ease-in-out;
  }
  &__contentOpen {
    opacity: 0.4;
    height: 100%;
    width: 100%;
  }


}
