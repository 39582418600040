:root {
  --black: #000000;
  --black2: #333333;
  --black2_opacity05: rgba(51, 51, 51, 0.5);
  --white: #ffffff;
  --primaryColor: #409280;
  --lightPrimary: #e7fef9;
  --red: #fb604b;
  --grey: #b1b1b1;
  --grey2: #f3f3f3;
  --grey3: #c4c4c4;
  --yellow: #fbdc4b;
  --yellow2: rgba(253, 216, 53, 0.53);
  --lightGrey: #fbfbfb;
  --lightGrey2: #eeeeee;
  --darkGrey: #59697f;
  --green: #179c7d;
  --blue: #3d82bb;
  --textForPrimaryColor: var(--white);
  --successColor-opacity020: rgba(23, 156, 125, 0.2);
}

body {
  white-space: pre-line;
}
