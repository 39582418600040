@import 'src/shared/globalStyles';

.sidebar {
  background-color: $sidebarBackground;
  min-width: $sidebarSize;
  max-width: $sidebarSize;
  min-height: calc(100% - #{$topbarSize});
  height: calc(100% - #{$topbarSize});
  display: flex;
  flex-direction: column;
  padding: calculateRem(8);
  &__fixed {
    height: 100vh;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.fraunhoferLogo {
  height: 30.25;
  width: 175px;
}

.poweredByFraunhoferLogo {
  height: 11px;
  width: 71px;
}

.itemContainer {
  width: 100%;
  display: flex;
  padding-left: 20px;
  align-items: center;
  outline: 0;
  &:hover {
    cursor: pointer;
    background-color: $sidebarItemColor;
  }

  &:focus {
    cursor: pointer;
    background-color: $sidebarItemColor;
  }

  &.activeItemContainer {
    background-color: $sidebarActiveItemColor;
    & * {
      font-family: LatoBold;
    }
  }
}

.item {
  padding-left: 18px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.sidebarActiveItem,
.sidebarActiveItem * {
  color: $sidebarActiveItemTextColor;
  fill: $sidebarActiveItemTextColor !important;
}
