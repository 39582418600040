@import 'src/shared/globalStyles';

.svg.white path {
  fill: $invertedTextColor;
}

.svg.primary path {
  fill: $primaryColor;
}

.svg.black path {
  fill: $textColor;
}

.svg.black2 path {
  fill: $black2;
}

.svg.red path {
  fill: $alertColor;
}

.svg.darkGrey path {
  fill: $greyTextColor;
}

.svg.grey path {
  fill: $borderColor2;
}

.svg.blue path {
  fill: $blue;
}
