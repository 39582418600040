@import 'src/shared/globalStyles';

.chart {
  &__container {
    border: calculateRem(1) solid $lightGrey2;
    position: relative;
  }
  &__bubbleContainer {
    display: flex;
  }
  &__bubbleContainerHorizontal {
    display: flex;
    border: calculateRem(1) solid $lightGrey2;
    position: relative;
    flex-direction: column;
  }
  &__column {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:hover,
    &:hover .bubble__containerEmpty,
    &:hover .header__containerEmpty,
    &__selected {
      background-color: #edf2f8;
    }
  }
}

.bubble {
  &__container {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  &__containerEmpty {
    background-color: $lightGrey;
  }
  &__median {
    display: inline-block;
    background-color: $primaryColor;
    width: calculateRem(16);
    height: calculateRem(4);
    border-radius: calculateRem(3);
  }
  &__medianHorizontal {
    display: inline-block;
    background-color: $primaryColor;
    height: calculateRem(16);
    width: calculateRem(4);
    border-radius: calculateRem(3);
  }
  &__medianContainer {
    position: absolute;
  }

  &__dotContainer {
    align-items: center;
  }

  &__dotContainer,
  &__medianContainer {
    display: flex;
    justify-content: center;
  }
  &__dot {
    height: calculateRem(8);
    width: calculateRem(8);
    background-color: $blue;
    opacity: 0.5;
    border-radius: 50%;
    display: inline-block;
  }
}

.paginator {
  position: absolute;
  top: 35%;
  width: calculateRem(48);
  height: calculateRem(48);
  background-color: $blue;
  cursor: pointer;
  opacity: 0.25;
  border-radius: calculateRem(2);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.25s;
  &:hover {
    opacity: 1;
  }
  &__sm {
    width: calculateRem(24);
    height: calculateRem(24);
  }
}
.chartBubbleHorizontal {
  display: inline-block;
  white-space: nowrap;
  margin-right: 8px;
}
.header {
  &__container {
    display: flex;
  }
  &__containerEmpty {
    background-color: $lightGrey;
  }
  &__textContainer {
    justify-content: flex-start;
    height: calculateRem(78);
    border-bottom: calculateRem(1) solid $lightGrey2;
    &:hover .header__textTooltip {
      display: block;
    }
  }
  &__textContainerBubble {
    &:hover .header__textTooltipBubble {
      display: block;
    }
  }
  &__textTooltipBubble {
    background-color: $white;
    border: calculateRem(1) solid $grey2;
    box-shadow: 0 calculateRem(4) calculateRem(4) rgba(0, 0, 0, 0.25);
    border-radius: calculateRem(4);
    width: fit-content;
    position: absolute;
    display: none;
    top: calculateRem(78);
    padding: calculateRem(12) calculateRem(8);
    margin-left: calculateRem(-58);
    z-index: $tooltipIndex;

    & span:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-left: calculateRem(8) solid transparent;
      border-bottom: calculateRem(8) solid $white;
      border-right: calculateRem(8) solid transparent;
      left: calculateRem(70);
      top: calculateRem(-8);
    }
  }
  &__textTooltip {
    background-color: $white;
    border: calculateRem(1) solid $grey2;
    box-shadow: 0 calculateRem(4) calculateRem(4) rgba(0, 0, 0, 0.25);
    border-radius: calculateRem(4);
    width: calculateRem(156);
    min-height: calculateRem(62);
    position: absolute;
    display: none;
    top: calculateRem(78);
    padding: calculateRem(12) calculateRem(8);
    margin-left: calculateRem(-58);
    z-index: $tooltipIndex;

    & span:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-left: calculateRem(8) solid transparent;
      border-bottom: calculateRem(8) solid $white;
      border-right: calculateRem(8) solid transparent;
      left: calculateRem(70);
      top: calculateRem(-8);
    }
  }
  &__textTooltipFirst {
    margin-left: calculateRem(-18);
    & span:before {
      left: calculateRem(28);
    }
  }
  &__textTooltipLast {
    margin-left: calculateRem(-98);
    & span:before {
      left: auto;
      right: calculateRem(28);
    }
  }
  &__linkTooltip {
    text-decoration: underline;
  }
  &__textBox {
    transform: rotate(-90deg);
    transform-origin: 45% 75%;
    width: calculateRem(56);
    margin-top: calculateRem(25);
  }
  &__text {
    font-size: calculateRem(12);
  }
  &__textPlaceholder {
    width: calculateRem(13);
    height: calculateRem(56);
    background-color: $lightGrey2;
    border-radius: calculateRem(2);
    margin-top: calculateRem(11);
    margin-left: calculateRem(13);
  }
}

.coloredChart {
  &__container {
    display: flex;
    height: calculateRem(88);
    padding-bottom: calculateRem(20);
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;
    border-top: calculateRem(1) solid $lightGrey2;
  }
  &__title {
    position: absolute;
    top: calculateRem(14);
    left: calculateRem(6);
    width: calculateRem(300);
    pointer-events: none;
  }
  &__box {
    height: calculateRem(24);
    border-radius: calculateRem(4);
  }

  &__red {
    background-color: $red;
  }
  &__green {
    background-color: $primaryColor;
  }
  &__yellow {
    background-color: $yellow;
  }
  &__grey {
    background-color: var(--grey3);
  }

  &__redWithOpacity {
    background-color: rgba(251, 96, 75, 0.3);
  }
  &__greenWithOpacity {
    background-color: rgba(64, 146, 128, 0.3);
  }
  &__yellowWithOpacity {
    background-color: rgba(251, 220, 75, 0.3);
  }
  &__empty {
    background-color: $grey2;
  }
}
