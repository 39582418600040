@import '../globalStyles.scss';

$modalHeight: 455px;
$modalWidth: 530px;
$modalContentHeight: 364px;

.modal {
  background: $backgroundColor;
  display: inline;
  opacity: 0;
  -webkit-transition: transform 400ms;
  -moz-transition: transform 400ms;
  transition: transform 400ms ease;
  will-change: opacity;
  outline: none;
  animation-fill-mode: both;
  position: relative;
  overflow-y: hidden;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
}

.modalHeader {
  position: sticky;
  top: 0;
  background: $backgroundColor;
  //border-bottom: 1px solid $borderColor;
  z-index: 9;
}

.modalContent {
  height: 100%;
  overflow-y: scroll;
}

.modal.modalVisible {
  opacity: 1;
  -webkit-transition: transform 400ms ease;
  -moz-transition: transform 400ms ease;
  transition: transform 400ms ease;
  transform: scale(0.96);
  animation-fill-mode: both;
  min-width: $modalWidth;
}

.modalFixedBottom {
  position: sticky;
  bottom: 0;
  background-color: $backgroundColor;
  z-index: 9;
  border-top: $borderSizeXs solid $borderColor;
}

@media only screen and (max-width: $media-computer-min) {
  .modal {
    height: 100vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    min-width: 100vw;
  }

  .modal.modalVisible {
    top: 0;
    max-height: 100%;
    height: 100%;
    max-width: 100%;
    transform: scale(1);
  }
}

.confirmationModal {
  max-width: 530px;
  &__container {
    padding: calculateRem(24);
  }
  &__headerButton {
    position: absolute;
    right: calculateRem(24);
    top: calculateRem(24);
  }
  &__buttonsSection {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__cancelButton {
    background-color: transparent;
    color: $blue;
  }
}
