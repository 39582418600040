@import '../globalStyles.scss';

.separatorLight {
  width: 100%;
  border-top: 1px solid $separatorLight;
}

.separatorDark {
  width: 100%;
  border-top: 1px solid $separatorDark;
}

.midHeight {
  height: 50%;
}

.separatorPrimaryColor {
  width: 100%;
  border-bottom: 2px solid $primaryColor;
  padding-bottom: 6px;
}
