@import '../globalStyles.scss';

$circleSize: 32px;

.circle {
  background-color: $primaryColor;
  border-radius: 50%;
  max-width: $circleSize;
  width: $circleSize;
  max-height: $circleSize;
  height: $circleSize;
  min-height: $circleSize;
  min-width: $circleSize;
  overflow: hidden;
}

.tinyCircle {
  background-color: $primaryColor;
  border-radius: 50%;
  max-width: calculateRem(15);
  width: calculateRem(15);
  max-height: calculateRem(15);
  height: calculateRem(15);
  min-height: calculateRem(15);
  min-width: calculateRem(15);
  overflow: hidden;
}

.circleImage {
  height: $circleSize;
  width: $circleSize;
  max-height: $circleSize;
  max-width: $circleSize;
  min-height: $circleSize;
  min-width: $circleSize;
  object-fit: cover;
}
