@import '../globalStyles.scss';

.tag {
  display: flex;
  align-items: center;
  height: 2.5rem;
  color: $white;
  padding: 2px 6px;
  border-radius: $borderRadiusMd;
}

.industry {
  background-color: $industryTagColor;
}
