@import 'src/shared/globalStyles';

.upload {
  &__emptyImage {
    height: calculateRem(243);
    width: calculateRem(255);
  }
}

.importProjectList {
  &__table {
    td:nth-child(1) {
      background-color: $yellow2;
    }
    th:nth-child(1) {
      background-color: $yellow2;
    }
  }

  &__row {
    border-bottom: calculateRem(1) solid $borderColor4;
  }

  &__column {
    padding: calculateRem(16);

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }

  &__header {
    text-align: left;
    padding: calculateRem(16);
  }
}

.header {
  &__container {
    display: flex;
  }

  &__textContainer {
    justify-content: flex-start;
    height: calculateRem(78);
    border-bottom: calculateRem(1) solid $lightGrey2;
    &:hover .header__textTooltip {
      display: block;
    }
  }

  &__textTooltip {
    background-color: $white;
    border: calculateRem(1) solid $grey2;
    box-shadow: 0 calculateRem(4) calculateRem(4) rgba(0, 0, 0, 0.25);
    border-radius: calculateRem(4);
    width: calculateRem(456);
    min-height: calculateRem(62);
    position: absolute;
    display: none;
    padding: calculateRem(12) calculateRem(8);
    z-index: $tooltipIndex;

    & span:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
    }
  }
}
.errorItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  font-size: calculateRem(15);

  &__prop {
    font-size: calculateRem(16);
    margin-right: 5px;
    color: red;
  }
}
