@import '../globalStyles.scss';

.h1 {
  font-family: $fontFamilyBold;
  font-size: $fontSizeH1;
  color: $textColor;
}

.h2 {
  font-family: $fontFamilyBold;
  font-size: $fontSizeH2;
  color: $textColor;
}

.h3 {
  font-family: $fontFamilyBold;
  font-size: $fontSizeH3;
  color: $textColor;
}

.h4 {
  font-family: $fontFamilyBold;
  font-size: $fontSizeH4;
  line-height: 24px;
  color: $textColor;
  line-height: 22px;
}

.baseParagraph {
  font-family: $fontFamilyNormal;
  font-style: normal;
  color: $textColor;
  font-weight: 100;
  line-height: 22px;
  word-wrap: break-word;
}

.big {
  font-size: $fontSizeBig;
}

.body {
  font-size: $fontSizeBody;
}

.small {
  font-size: $fontSizeSmall;
}

.tiny {
  font-size: $fontSizeTiny;
}

.red {
  color: $alertColor;
}

.link {
  color: $blue;
}

.grey {
  color: $borderColor2;
}

.white {
  color: $invertedTextColor;
}

.darkGrey {
  color: $greyTextColor;
}

.blue {
  color: $blue;
}

.black2 {
  color: $black2;
}

.thin {
  font-family: $fontFamilyThin;
}

.light {
  font-family: $fontFamilyLight;
}

.regular {
  font-family: $fontFamilyNormal;
}

.bold {
  font-family: $fontFamilyBold;
}

.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.underlined {
  text-decoration: underline;
}
