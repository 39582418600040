@import '../globalStyles.scss';

.commentContent {
  background: $white;
  border: calculateRem(1) solid $lightGrey2;
  height: calculateRem(32);
  min-width: calculateRem(32);
  width: calculateRem(32);
  box-sizing: border-box;
  box-shadow: calculateRem(0) calculateRem(4) calculateRem(8) rgba(0, 0, 0, 0.1);
  border-radius: calculateRem(2);
  display: flex;
  margin-top: calculateRem(4);
  justify-content: center;
  align-items: center;
}

.reactionIcon {
  background: $white;
  border: calculateRem(1) solid $lightGrey2;
  height: calculateRem(24);
  min-width: calculateRem(24);
  width: calculateRem(24);
  box-sizing: border-box;
  box-shadow: calculateRem(0) calculateRem(4) calculateRem(8) rgba(0, 0, 0, 0.1);
  border-radius: calculateRem(2);
  display: flex;
  margin-top: calculateRem(4);
  justify-content: center;
  align-items: center;
}

.reactionButton {
  @extend .reactionIcon;
  cursor: pointer;
}

.reactionComments {
  @extend .reactionIcon;
}

.reactionBadge {
  position: relative;
  top: -2px;
  left: 8px;
  z-index: 9999;
}

.commentBox {
  border: calculateRem(1) solid $lightGrey2;
  display: flex;
  flex-direction: column;
  padding: calculateRem(24) calculateRem(24) calculateRem(8) calculateRem(24);
}

.commentIcons {
  display: flex;
  margin-top: calculateRem(8);
}

.commentEdit {
  display: flex;
  flex-direction: column;
  margin: calculateRem(24) 0;
  border-bottom: calculateRem(1) solid $borderColor3;
  &:last-child {
    border-bottom: none;
  }
}

.comment {
  padding: calculateRem(16) 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.confirmationModal {
  padding: calculateRem(32) calculateRem(40) calculateRem(16) calculateRem(40);
}
