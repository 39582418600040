@import 'src/shared/globalStyles';
.dropdown {
  position: relative;
  display: block;
  width: auto;
  cursor: pointer;
  background-color: #000;

  &__face,
  &__items {
    padding: 20px;
  }

  &__items {
    margin: 0;
    position: absolute;
    right: 0;
    width: auto;
    list-style: none;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    visibility: hidden;
    background-color: #fff;
    border: 0.1875rem #000 solid;
    z-index: 99;
  }

  &__arrow {
    border-bottom: 2px solid #000;
    border-right: 2px solid #000;
    position: absolute;
    top: 50%;
    right: 30px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: right;
  }

  input {
    display: none;

    &:checked ~ .dropdown__items {
      top: calc(100% + 5px);
      visibility: visible;
      opacity: 1;
    }
  }
}

.ddWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  pointer-events: all;

  .ddContainer {
    position: relative;
  }

  .ddHeaderOutlined {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    align-items: center;
    height: calculateRem(43);
    padding: 0 calculateRem($size-sm);
    background-color: $white;
    border-color: $black2;
    border-style: solid;
    border-width: calculateRem(1.5);
    outline: none;

    &__error {
      border-color: $red;
    }
  }

  .ddHeader {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    align-items: center;
    height: calculateRem(43);
    padding: 0 calculateRem($size-sm);
    background-color: $white;
    outline: none;

    &__action {
      padding-top: calculateRem(4);
    }

    &__error {
      border-color: $red;
    }
  }

  .ddList::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .ddList::-webkit-scrollbar:vertical {
    padding: 2px;
    width: 8px;
  }

  .ddList::-webkit-scrollbar-thumb {
    background-color: $grey;
  }

  .ddListContainer {
    background-color: $white;
    border: calculateRem(1.5) solid $black2;
    position: absolute;
    margin-left: 2%;
    padding: 0 1px 1.5px 1px;
    min-width: 96%;
    top: calculateRem($size-xxxl);
    white-space: nowrap;
    z-index: $dropdownIndex;
  }

  .ddList {
    max-height: 230px;
    overflow-y: auto;
    li {
      list-style-type: none;
      button {
        outline: none;
        display: flex;
        justify-content: space-between;
        background-color: $white;
        padding: calculateRem(15) calculateRem($size-sm);
        border: 0;
        text-align: left;
        width: 100%;
        cursor: pointer;
        &:hover,
        &:focus {
          background-color: $greyBackgroundColor;
        }
      }
    }
  }
}
