@import '../globalStyles';

.container {
  display: block;
  position: relative;
  padding-left: calculateRem($size-xxl);
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: $white;
  border: 1px solid $black;
  border-radius: 50%;
}

.container:hover input ~ .checkmark {
  border: 2px solid $primaryColor;
}

.container input:checked ~ .checkmark {
  border: 2px solid $primaryColor;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 3px;
  left: 3px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: $primaryColor;
}
