@import '../globalStyles.scss';

@media only screen and (min-width: $media-computer-min) {
  .desktop {
    display: contents;
  }

  .mobile {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (max-width: $media-computer-min) {
  .mobile {
    display: contents;
  }

  .desktop {
    display: none;
    visibility: hidden;

  }
}