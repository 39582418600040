@import '../globalStyles.scss';

$pagination-size: 4rem;

.paginationNavigationItem,
.paginationNumbers {
  user-select: none;
  background: white;
  min-width: $pagination-size;
  min-height: $pagination-size;
  max-width: $pagination-size;
  max-height: $pagination-size;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: 0;

  &:hover:not(.active) {
    background: $greyBackgroundColor;
  }

  &.hidden {
    pointer-events: none;
    visibility: hidden;
  }
}

.paginationNavigationItem {
  border: 1px solid $black2;
}

.paginationNumbers.active {
  background: $black2;
  cursor: initial;
}
