@import '../globalStyles.scss';

.container {
  min-height: calculateRem(35);
}

.icon {
  background: $white;
  border: calculateRem(1) solid $lightGrey2;
  height: calculateRem(24);
  min-width: calculateRem(24);
  width: calculateRem(24);
  box-sizing: border-box;
  box-shadow: calculateRem(0) calculateRem(4) calculateRem(8) rgba(0, 0, 0, 0.1);
  border-radius: calculateRem(2);
  display: flex;
  margin-top: calculateRem(4);
  justify-content: center;
  align-items: center;
}

.button {
  @extend .icon;
  cursor: pointer;
}

.selectedButton {
  @extend .icon;
  cursor: pointer;
  border: calculateRem(1) solid $primaryColor;
  background: $lightPrimary;
}

.badgeSpacer {
  width: calculateRem(15);
  min-width: calculateRem(15);
}

.comments {
  @extend .icon;
}

.badge {
  position: relative;
  top: -4px;
  left: 8px;
  z-index: 2;
}

.buttonLabel {
  cursor: pointer;
}
