@import '../globalStyles.scss';

.container {
  min-height: 100%;
}

@media only screen and (min-width: $media-computer-min) {
  .container {
    display: flex;
    justify-content: center;
  }

  .withLeftAndRightSidebar {
    //min-width: 830px;
    padding-left: $spacing-xl;
    padding-right: $spacing-xl;
  }

  .withLeftSidebar {
    min-width: 1190px;
    max-width: 1190px;
    width: 100%;
    margin-top: $spacing-xxl;
    padding-left: $spacing-xl;
    padding-right: $spacing-xl;
  }
}

@media only screen and (max-width: $media-computer-min) {
  .container {
    display: flex;
    justify-content: center;
  }
}

.asFullWidthInBigScreen {
  width: 100%;
  margin-top: $spacing-xxl;
  padding-left: $spacing-xl;
  padding-right: $spacing-xl;
}

.gridContainer {
  min-height: 100%;
  max-height: max-content;
  display: grid;
  grid-template-areas:
    'mid members'
    'mid members';
  grid-template-columns: 830px 1fr;
  width: 100%;
  height: inherit;
}

@media only screen and (max-width: $media-computer-min) {
  .gridContainer {
    min-height: 100%;
    display: grid;
    grid-template-areas:
      'mid'
      'members';
    grid-template-rows: 1fr 10vh;
    grid-template-columns: 1fr;
    width: 100%;
    height: 100%;
  }
}
