@import 'src/shared/globalStyles';

.report {
  &__container {
    border-bottom: calculateRem(1) solid $borderColor3;
    padding: calculateRem(32) calculateRem(24);
  }
  &__item {
    border: calculateRem(1) solid $lightGrey2;
  }
  &__legendApproved,
  &__legendTotal {
    width: calculateRem(32);
    border-radius: calculateRem(4);
  }
  &__legendApproved {
    background-color: $primaryColor;
  }
  &__legendTotal {
    background-color: $greyBackgroundColor;
  }
  &__stackedBarChartContainer {
    width: 100%;
    position: relative;
  }
  &__pieChartContainer {
    width: calculateRem(400);
  }
}

.header {
  &__align {
    display: flex;
    justify-content: space-between;
  }
}
