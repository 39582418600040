@import '../globalStyles.scss';

.dimmer {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.dimmerVisible {
  background: $dimmerColor;
  pointer-events: all;
}

@media only screen and (max-width: 600px) {
  .dimmer {
    justify-content: initial;
  }
}
