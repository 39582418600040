@import 'src/shared/globalStyles';

.commentBox {
  border: calculateRem(1) solid $lightGrey2;
  display: flex;
  flex-direction: column;
  padding: calculateRem(24) calculateRem(24) calculateRem(8) calculateRem(24);
}

.empty {
  &__container {
    display: flex;
    padding: calculateRem(40);
    align-items: center;
  }
  &__image {
    height: calculateRem(118);
  }
}

.collapseToggle {
  align-items: center;
  cursor: pointer;
}

.singleCommentContainer {
  border: calculateRem(1) solid $lightGrey2;
  position: relative;
  padding: calculateRem(24);
}