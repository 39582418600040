$roundedLogoWidthSm: 10rem;
$roundedLogoHeightSm: 10rem;
$roundedLogoWidthMd: 13.125rem;
$roundedLogoHeightMd: 13.125rem;

$squaredLogoWidthSm: 11.125rem;
$squaredLogoHeightSm: 8rem;
$squaredLogoWidthMd: 18.5rem;
$squaredLogoHeightMd: 13.125rem;

.roundedLogo {
  border-radius: 50%;
  object-fit: cover;
}

.roundedLogosm {
  width: $roundedLogoWidthSm;
  height: $roundedLogoHeightSm;
}

.roundedLogomd {
  width: $roundedLogoWidthMd;
  height: $roundedLogoHeightMd;
}

.squaredLogo {
  object-fit: contain;
}

.squaredLogosm {
  width: $squaredLogoWidthSm;
  height: $squaredLogoHeightSm;
}

.squaredLogomd {
  width: $squaredLogoWidthMd;
  height: $squaredLogoHeightMd;
}
