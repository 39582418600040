@import 'src/shared/globalStyles';

.toaster {
  position: fixed;
  right: 24px;
  top: 32px;
  height: 64px;
  width: 350px;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  z-index: 1000;
}

.success {
  border-left: 8px solid $successColor;
}

.failure {
  border-left: 8px solid $alertColor;
}
