@import '../globalStyles.scss';

.PrimaryButtonWithWidth {
  font-family: $fontFamilyNormal;
  background: $primaryColor;
  color: $primaryButtonTextColor;
  border-radius: $borderRadiusXs;
  padding: 0 7rem;
  font-size: $fontSizeBody;
  transition: 200ms ease opacity;
  cursor: pointer;
  border: 0;
  box-shadow: $buttonBoxShadow;
  height: 47px;
  width: 100%;
  transition: 0.2s ease-in all;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    outline: 0 !important;
    box-shadow: $buttonBoxShadowHover;
  }

  &:not([disabled]):hover {
    box-shadow: $buttonBoxShadowHover;
  }
}

.PrimaryButton {
  font-family: $fontFamilyNormal;
  background: $primaryColor;
  color: $primaryButtonTextColor;
  border-radius: $borderRadiusXs;
  padding: 0 7rem;
  font-size: $fontSizeBody;
  transition: 200ms ease opacity;
  cursor: pointer;
  border: 0;
  box-shadow: $buttonBoxShadow;
  height: 47px;
  //width: 100%;
  transition: 0.2s ease-in all;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    outline: 0 !important;
    box-shadow: $buttonBoxShadowHover;
  }

  &:not([disabled]):hover {
    box-shadow: $buttonBoxShadowHover;
  }
}

.transparentButton {
  font-family: $fontFamilyNormal;
  background: transparent;
  color: $transparentButtonTextColor;
  border-radius: $borderRadiusXs;
  padding: 0 7rem;
  font-size: $fontSizeBody;
  transition: 200ms ease opacity;
  cursor: pointer;
  border: $borderSize solid $transparentButtonTextColor;
  box-shadow: $buttonBoxShadow;
  height: 47px;
  width: max-content;
  transition: 0.2s ease-in all;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus {
    outline: 0 !important;
    box-shadow: $buttonBoxShadowHover;
  }

  &:not([disabled]):hover {
    box-shadow: $buttonBoxShadowHover;
  }
}

.bottomFluid {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 52px;
}

.buttonIcon {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}
