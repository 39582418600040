.linkBox {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.relativeBox {
  position: relative;
}

.absoluteBox {
  position: absolute;
}
